export const calculateCalories = nutritionObj => {
  const { protein, carbohydrate, fat } = nutritionObj

  const calProtein = +protein * 4
  const calCarbohydrate = +carbohydrate * 4
  const calFat = +fat * 9
  const calTotal = calProtein + calCarbohydrate + calFat

  const percentProtein = Math.floor((calProtein * 100) / calTotal)
  const percentCarbohydrate = Math.floor((calCarbohydrate * 100) / calTotal)
  const percentFat = 100 - percentProtein - percentCarbohydrate

  return { calTotal, percentProtein, percentCarbohydrate, percentFat }
}
