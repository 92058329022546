import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import Recipe from "../components/recipe"

import { pageSlug } from "../lib/page-slug-full"
import { breakpoints } from "../parameters/theme"

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 4em;
  max-width: ${breakpoints.tablet}px;
  padding: 1em 1em 0;
  overflow-wrap: break-word;

  &::after {
    clear: both;
    content: ".";
    display: block;
    height: 0;
    visibility: hidden;
  }

  & > *:not(:first-child) {
    margin-top: 1em;
  }

  & > a {
    display: block;
  }
`

const RecipeTemplate = ({ pageContext, location }) => {
  const { title, slug } = pageContext

  return (
    <Layout
      location={location}
      pageSlug={pageSlug({ slug, internal: { type: "ContentfulRecipe" } })}
      pageType="page,recipe"
      pageClassName="page recipe"
      title={title}
      nodeData={{}}
    >
      <Container>
        <Recipe slug={slug} page />
      </Container>
    </Layout>
  )
}

export default RecipeTemplate
