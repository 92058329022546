import React, { useContext } from "react"
import Img from "gatsby-image"
import { MDXRenderer } from "gatsby-plugin-mdx"

import ContentfulDataContext from "../context/contentful-data"

import { calculateCalories } from "../lib/recipe"

const Ricipe = props => {
  const { allContentfulRecipe } = useContext(ContentfulDataContext)

  if (!props.slug) {
    return ""
  }

  const filteredRecipes = allContentfulRecipe.edges.filter(({ node }) => {
    return node.slug === props.slug
  })

  if (!filteredRecipes || !filteredRecipes.length) {
    return ""
  }

  const [{ node: recipe }] = filteredRecipes

  // Calculate calory values
  recipe.nutritionParsed = JSON.parse(recipe.nutrition.internal.content)
  const { protein, carbohydrate, fat } = recipe.nutritionParsed

  const {
    calTotal,
    percentProtein,
    percentCarbohydrate,
    percentFat,
  } = calculateCalories(recipe.nutritionParsed)

  const Header = ({ id, children }) => (
    <>
      {props.headerType === "h3" ? (
        <h3 id={id} className="recipe-title">
          {children}
        </h3>
      ) : (
        <h2 id={id} className="recipe-title">
          {children}
        </h2>
      )}
    </>
  )

  return (
    <div className="recipe">
      {!props.embedded ? (
        <>
          {!props.page ? (
            <Header id={`recipe-title-${recipe.slug}`}>
              <a href={`/recipes/${recipe.slug}`}>{recipe.title}</a>
            </Header>
          ) : (
            ""
          )}
          {recipe.excerpt ? (
            <div className="recipe-content">
              {recipe.childrenContentfulMdx ? (
                <MDXRenderer>
                  {recipe.childrenContentfulMdx[0].childMdx.body}
                </MDXRenderer>
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: recipe.excerpt ? recipe.excerpt.excerpt : "",
                  }}
                ></div>
              )}
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
      <div className="recipe-ingredients">
        <h3 id={`ingredients-${recipe.slug}`}>Ingredients</h3>
        <ul>
          {recipe.ingredients.map(ing => (
            <li key={ing}>{ing}</li>
          ))}
        </ul>
      </div>
      {recipe.mealImage ? (
        <div className="recipe-meal-image">
          <Img fluid={recipe.mealImage.fluid} alt={recipe.title} />
        </div>
      ) : (
        ""
      )}
      <div className="recipe-directions">
        <h3 id={`directions-${recipe.slug}`}>Directions</h3>
        <ol>
          {recipe.directions.map(dir => (
            <li key={dir}>{dir}</li>
          ))}
        </ol>
      </div>
      <div className="recipe-nutrition">
        <div className="recipe-nutrition-energy">{calTotal}&nbsp;kcal</div>
        <div className="recipe-nutrition-macros">
          <div
            className="recipe-nutrition-macros-protein"
            style={{ width: `${percentProtein}%` }}
            title="protein"
          >
            {protein} g protein
          </div>
          <div
            className="recipe-nutrition-macros-carbs"
            style={{ width: `${percentCarbohydrate}%` }}
            title="carbs"
          >
            {carbohydrate} g carbs
          </div>
          <div
            className="recipe-nutrition-macros-fat"
            style={{ width: `${percentFat}%` }}
            title="fat"
          >
            {fat} g fat
          </div>
        </div>
      </div>
      {props.page ? (
        <>
          {recipe.body ? (
            <div className="recipe-body">
              {recipe.childrenContentfulMdx ? (
                <MDXRenderer>
                  {recipe.childrenContentfulMdx[1].childMdx.body}
                </MDXRenderer>
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: recipe.body ? recipe.body.body : "",
                  }}
                ></div>
              )}
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
    </div>
  )
}

export default Ricipe
